import { Component, Vue } from "vue-property-decorator";

import { SideNav } from "@/layout";
import { HomeworkModel } from "@/models";
import { Common } from "../Common";

export class SearchForm {
    condi = '';
    keyword = '';
    uploadTime = '';
}

type response = { [key: string]: unknown };

@Component<AdminHomeworkAudioController>({
    components: {
        SideNav
    }
})
export default class AdminHomeworkAudioController extends Vue {
    private searchForm = new SearchForm();
    private audioList: object = {};
    private num = 0;
    private pageHtml = "";

    private token = window.localStorage.getItem('adminToken') as string;

    private errorMsg = "";
    private openLB = false;

    public async created() {
        this.getList();
    }

    public async getList() {
        const item: { [key: string]: string } = {
            page: this.$route.query.page as string,
            uploadTime: this.searchForm.uploadTime,
            condi: this.searchForm.condi,
            keyword: this.searchForm.keyword,
            token: this.token,
        }
        const data = await HomeworkModel.getAudioList(item) as response;
        Common.checkToken(data.ERR_CODE as number);
        this.num = data.num as number;
        this.audioList = data.list as object;
        this.pageHtml = data.pageHtml as string;
    }
}
